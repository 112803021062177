<template>
  <div class="section-collection-filter">
    <tml-form form-name="collection-filter">
      <div class="flex justify-between p-4">
        <h4 class="mb-0">
          Filters
          <span v-if="filterMeta.enableFilters"> ({{ selectedCount }}) </span>
        </h4>
        <tml-toggle
          v-if="!isMobileView"
          v-model="filterMeta.enableFilters"
          class="!m-0"
          field-name="filter-toggle"
        />
      </div>
    </tml-form>
    <template v-for="(filterOption, nameIndex) in filterOptions">
      <tml-expandable-container
        v-if="filterOption.filterableOptions.length"
        :key="nameIndex"
        ref="containers"
        class="filter-container"
        :title="startCase(nameIndex)"
        only-hide
        no-bottom-margin
        no-transition
        :prevent-outer-close="false"
      >
        <template #button>
          <tml-anchor
            v-if="filters[nameIndex].length > 0"
            class="pr-2 cursor-pointer"
            text="Reset"
            @click.stop="clearSingleFilter(nameIndex)"
          />
        </template>
        <tml-segmented-select
          :ref="nameIndex"
          :value="getActiveFilterValue(nameIndex)"
          :items="filterOption.filterableOptions"
          :multiselect="filterOption.multiselect"
          identifier="property"
          @input="filterProducts($event, nameIndex)"
        >
          <template #default="{select, deselect, allItems}">
            <div
              class="filter-options-container"
              :class="{'filter-options-overlay': !isMobileView}"
            >
              <div
                v-for="(filterProp, index) in allItems"
                :key="index"
                class="filter-option p-4 cursor-pointer"
                @click="
                  () => {
                    if (filterProp.selected) {
                      deselect(filterProp);
                    } else {
                      select(filterProp);
                    }
                  }
                "
              >
                <div class="relative">
                  <div class="title">
                    <div v-if="nameIndex === 'colours'">
                      <tml-color-square
                        class="colour-square inline-block"
                        :color="`${filterProp.property}-Group`"
                        disable-tooltip
                      />
                    </div>
                    <p class="primary-text ml-4">
                      {{ filterProp.property }}
                    </p>
                  </div>
                  <font-awesome-icon
                    v-if="filterProp.selected"
                    class="float-right absolute filter-icon mr-0"
                    :icon="faCheck"
                  />
                </div>
              </div>
            </div>
          </template>
        </tml-segmented-select>
      </tml-expandable-container>
    </template>
    <div v-if="isMobileView && anyFilterEnabled" class="container mt-4">
      <tml-button
        secondary
        text="Remove Filters"
        fill
        @click="clearAllFilters"
      />
    </div>
  </div>
</template>

<script>
import {faCheck} from '@fortawesome/pro-solid-svg-icons';
import VueScrollTo from 'vue-scrollto';
import Vue from 'vue';
import {union, startCase} from 'lodash';
import {maxDevice} from '@teemill/common/helpers';

Vue.use(VueScrollTo);

export default {
  name: 'SectionCollectionFilter',

  props: {
    filter: {
      required: true,
    },

    filters: Array,

    products: Array,
  },

  data() {
    return {
      faCheck,
      productBaseColours: [],
      setFilters: {
        types: [],
        colours: [],
        sizes: [],
      },
      initialLoad: false,
    };
  },

  computed: {
    filterOptions() {
      return this.filter.filterOptions;
    },
    filterMeta() {
      return this.filter.filterMeta;
    },
    isMobileView() {
      return maxDevice('md');
    },
    filterCounts() {
      const filter = this.filter;
      const filterOptions = filter.filterOptions;

      return {
        colours: filterOptions.colours.filterableOptions
          .filter(c => !!c.selected)
          .map(c => c.property).length,
        types: filterOptions.types.filterableOptions
          .filter(t => !!t.selected)
          .map(t => t.property).length,
        sizes: filterOptions.sizes.filterableOptions
          .filter(s => !!s.selected)
          .map(s => s.property).length,
      };
    },

    anyFilterEnabled() {
      return this.selectedCount >= 1;
    },

    filtersEnabled() {
      return this.filterMeta.enableFilters;
    },

    enabledFilters() {
      return this.filterMeta.enabledFilters;
    },

    selectedCount() {
      return (
        (this.filters?.types?.length ?? 0) +
        (this.filters?.colours?.length ?? 0) +
        (this.filters?.sizes?.length ?? 0)
      );
    },
  },

  watch: {
    filtersEnabled() {
      this.emitFilterSet();
    },

    selectedCount(value) {
      if (!value) {
        this.filterMeta.enableFilters = false;
      }
    },
  },

  created() {
    Object.assign(this.setFilters, this.filters);
  },

  methods: {
    startCase,

    clearAllFilters() {
      this.$refs.types[0].clear();
      if (this.$refs.colours && this.$refs.sizes) {
        this.$refs.colours[0].clear();
        this.$refs.sizes[0].clear();
      }

      this.filterMeta.enableFilters = false;
      this.$store.dispatch('overlayElements/close', 'collection-filter');
    },
    clearSingleFilter(filterName) {
      if (this.$refs[filterName]) {
        this.$refs[filterName][0].clear();
      }
    },

    filterProducts($event, nameIndex) {
      // force filters on whenever filters change
      this.filterMeta.enableFilters = true;

      if (nameIndex === 'colours') {
        this.setFilters.colours = $event.map(filter => filter.property);
      } else if (nameIndex === 'types') {
        this.setFilters.types = $event.map(filter => filter.property);
      } else if (nameIndex === 'sizes') {
        this.setFilters.sizes = $event.map(filter => filter.property);
      }

      this.emitFilterSet();
    },

    emitFilterSet() {
      if (this.filtersEnabled) {
        this.$emit('filter-set', this.setFilters);
      } else {
        this.$emit('filter-set', {types: [], colours: [], sizes: []});
      }
    },

    getActiveFilterValue(nameIndex) {
      const filterData = this.filters[nameIndex];

      if (filterData.length) {
        return filterData.map(filter => ({property: filter, selected: true}));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.section-collection-filter {
  border-radius: $border-radius;
}

.filter-options-container {
  width: auto;
  white-space: nowrap;
  overflow-y: auto;
  max-height: 300px;
}

.filter-options-overlay {
  border-radius: $border-radius;
  position: absolute;
  width: calc(100% - 2em);
  max-height: calc(100% - 20%);
  overflow-y: auto;
  z-index: 1;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
}

.filter-option {
  border-top: 1px solid $transparent-grey !important;
  background-color: var(--tml-page-background-color);
  .filter-icon {
    height: 18px;
    top: 2px;
    right: 0;
    width: 2em;
  }
  .title {
    display: flex;
  }
  .filter-icon {
    background-color: var(--tml-page-background-color);
  }
}
.primary-text {
  color: var(--tml-text-color);
}

.colour-square {
  height: 25px;
  width: 35px;
  border-radius: $border-radius;
  border: 1px solid $transparent-grey;
}
</style>

<style lang="scss">
.filter-container .header {
  border-top: 1px solid $transparent-grey !important;
}

.filter-options-container .colour-square .color-square {
  border-radius: 3px;
}
</style>
