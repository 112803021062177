<template>
  <div class="section-collection-sort pb-0">
    <tml-new-form name="collection-sort-by">
      <tml-new-select
        v-model="selectedSort"
        class="sort-select"
        name="sort-select"
        display-field="text"
        :options="sortOptions"
        clearable
        none-selected="Recommended"
        label="Sort By"
      >
        <template v-if="showIcon" #none-selected-content>
          <font-awesome-icon :icon="faArrowUpArrowDown" /> Recommended
        </template>
      </tml-new-select>
    </tml-new-form>
  </div>
</template>

<script>
import {faArrowUpArrowDown} from '@fortawesome/pro-light-svg-icons';
export default {
  name: 'SectionCollectionSort',

  props: {
    showIcon: Boolean,

    excludedSortOptions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      sortOptions: [
        {
          text: 'New In',
          value: 'new-in',
        },
        {
          text: 'Bestselling',
          value: 'bestsellerOrder',
          reverse: false,
        },
        {
          text: 'Price Low to High',
          value: 'price-asc',
        },
        {
          text: 'Price High to Low',
          value: 'price-desc',
        },
      ],
      selectedSort: null,
      faArrowUpArrowDown,
    };
  },

  watch: {
    selectedSort() {
      this.$emit('sort', this.selectedSort?.value || null);

      this.$router.push({
        query: {
          ...this.$route.query,
          sort: this.selectedSort?.value || null,
        },
      });
    },

    excludedSortOptions: {
      immediate: true,
      handler() {
        this.sortOptions = this.sortOptions.filter(
          option => !this.excludedSortOptions.includes(option.value)
        );
      },
    },
  },

  created() {
    if (this.$route.query.sort) {
      this.selectedSort = this.sortOptions.find(
        option => option.value === this.$route.query.sort
      );
    }
  },
};
</script>

<style lang="scss">
.sort-select {
  .controls-container {
    border-color: $transparent-grey;
  }
  .option-container {
    border-color: $transparent-grey;
  }
}
</style>
